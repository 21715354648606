import { DesignSystemProvider } from '@portalform/design-system';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';

import '../styles/globals.css';

export type AppPropsWithLayout = AppProps<{}> & {
	Component: NextPage;
};

export default function _App({
	Component,
	pageProps: { ...pageProps },
}: AppPropsWithLayout) {
	return (
		<>
			<Head>
				<title>Connect Shopify</title>
				<link rel="shortcut icon" href="/oversee.png" />
			</Head>
			<Toaster
				position="bottom-center"
				toastOptions={{
					className: 'min-w-[400px] text-[15px] border-[1px] border-zinc-100',
					duration: 10000,
					style: {
						boxShadow: 'none',
					},
				}}
			/>
			<DesignSystemProvider>
				<Component {...pageProps} />
			</DesignSystemProvider>
		</>
	);
}
